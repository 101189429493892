import "./polyfills";

// Stimulus boiler plate -- Needed to display the hamburger menu for signed in users.
import "controllers";

// If you don't start @rails/ujs, the sign out link in the hamburger menu doesn't work.
require("@rails/ujs").start();
require("turbolinks").start();

// include all the css
import("styles/website.scss");
